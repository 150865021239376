enum PaymentMethodId {
    Adyen = 'adyen',
    AdyenV2 = 'adyenv2',
    AdyenV2GooglePay = 'googlepayadyenv2',
    AdyenV3GooglePay = 'googlepayadyenv3',
    AdyenV3 = 'adyenv3',
    Affirm = 'affirm',
    Afterpay = 'afterpay',
    AmazonPay = 'amazonpay',
    ApplePay = 'applepay',
    Barclaycard = 'barclaycard',
    BlueSnapV2 = 'bluesnapv2',
    BlueSnapDirect = 'bluesnapdirect',
    Boleto = 'boleto',
    Bolt = 'bolt',
    Braintree = 'braintree',
    BraintreeAch = 'braintreeach',
    BraintreeVenmo = 'braintreevenmo',
    AuthorizeNetGooglePay = 'googlepayauthorizenet',
    BNZGooglePay = 'googlepaybnz',
    BraintreeAcceleratedCheckout = 'braintreeacceleratedcheckout',
    BraintreeGooglePay = 'googlepaybraintree',
    PayPalCommerceAcceleratedCheckout = 'paypalcommerceacceleratedcheckout',
    PayPalCommerceGooglePay = 'googlepaypaypalcommerce',
    BraintreeVisaCheckout = 'braintreevisacheckout',
    BraintreeLocalPaymentMethod = 'braintreelocalmethods',
    BraintreePaypalCredit = 'braintreepaypalcredit',
    CBAMPGS = 'cba_mpgs',
    CCAvenueMars = 'ccavenuemars',
    Checkoutcom = 'checkoutcom',
    CheckoutcomGooglePay = 'googlepaycheckoutcom',
    Clearpay = 'clearpay',
    Converge = 'converge',
    CybersourceV2GooglePay = 'googlepaycybersourcev2',
    DigitalRiver = 'digitalriver',
    Fawry = 'fawry',
    Humm = 'humm',
    Ideal = 'ideal',
    Klarna = 'klarna',
    Laybuy = 'laybuy',
    Masterpass = 'masterpass',
    Mollie = 'mollie',
    Moneris = 'moneris',
    Opy = 'opy',
    OrbitalGooglePay = 'googlepayorbital',
    Oxxo = 'oxxo',
    PaypalExpress = 'paypalexpress',
    PaypalPaymentsPro = 'paypal',
    PaypalCommerce = 'paypalcommerce',
    PaypalCommerceCredit = 'paypalcommercecredit',
    PaypalCommerceCreditCards = 'paypalcommercecreditcards',
    PaypalCommerceAlternativeMethod = 'paypalcommercealternativemethods',
    PaypalCommerceVenmo = 'paypalcommercevenmo',
    Qpay = 'qpay',
    Quadpay = 'quadpay',
    Ratepay = 'ratepay',
    SagePay = 'sagepay',
    Sepa = 'sepa',
    Sezzle = 'sezzle',
    SquareV2 = 'squarev2',
    StripeGooglePay = 'googlepaystripe',
    StripeUPEGooglePay = 'googlepaystripeupe',
    StripeV3 = 'stripev3',
    StripeUPE = 'stripeupe',
    WorldpayAccess = 'worldpayaccess',
    WorldpayAccessGooglePay = 'googlepayworldpayaccess',
    Zip = 'zip',
    TdOnlineMartGooglePay = 'googlepaytdonlinemart',
}

export default PaymentMethodId;
